export const menuListCoffee = [
    {
        title: "Эспрессо",
        size: null,
        sizeType: "",
        uno: 99,
        media: 138,
        grande: null,
        description: null,
        unique: false,
        tooltip: "Концентрированный и слегка вязкий напиток, для которого характерен яркий аромат и насыщенный вкус.",
    },
    {
        title: "Ристретто",
        size: null,
        sizeType: "",
        uno: 99,
        media: null,
        grande: null,
        description: null,
        unique: false,
        tooltip: "Крепкий, насыщенный, бодрящий свежеприготовленный кофе станет отличным началом дня!",
    },
    {
        title: "Американо/Фильтр",
        size: null,
        sizeType: "",
        uno: 131,
        media: 154,
        grande: null,
        description: null,
        unique: false,
        tooltip:
            "Чашка бодрящего черного кофе. Рецепт и название «американо» придумано итальянцами в качестве оскорбления американцев, предпочитающих фильтр-кофе благородному напитку эспрессо.",
    },
    {
        title: "Капучино",
        size: null,
        sizeType: "",
        uno: 148,
        media: 174,
        grande: 198,
        description: null,
        unique: false,
        tooltip: "Идеальное сочетание натурального бодрящего кофе и нежного молочного вкуса с нежной сладкой пенкой.",
    },
    {
        title: "Латте",
        size: null,
        sizeType: "",
        uno: 148,
        media: 174,
        grande: 198,
        description: null,
        unique: false,
        tooltip:
            "Идеальное сочетание натурального кофе и нежного молочного вкуса с нежной сладкой пенкой. Более молочный, чем капучино.",
    },
    {
        title: "Раф",
        size: null,
        sizeType: "",
        uno: 174,
        media: 206,
        grande: 232,
        description: null,
        unique: false,
        tooltip:
            "Эспрессо в сочетании со сливками, молоком и сиропом — все взбивается до образования однородной, нежной, сладкой пены.",
    },
    {
        title: "Флэт уайт",
        size: null,
        sizeType: "",
        uno: 174,
        media: 199,
        grande: null,
        description: null,
        unique: false,
        tooltip:
            "Яркое бодрящее сочетание эспрессо и небольшого количества вспененного молока. Был придуман новозеландским обжарщиком и бариста в результате экспериментов над балансом вкуса.",
    },
    {
        title: "Раф Канзас",
        size: null,
        sizeType: "",
        uno: null,
        media: 218,
        grande: 238,
        description: null,
        unique: true,
        tooltip: "Арахисово-попкорновый шедевр из Канзаса. Эспрессо, молоко, арахисовая паста, сироп попкорн.",
    },
    {
        title: "Раф Sin-o-Bon",
        size: null,
        sizeType: "",
        uno: null,
        media: 218,
        grande: 238,
        description: null,
        unique: true,
        tooltip: "Нежный раф с добавлением сиропа попкорна, ароматной корицы и щепоткой соли.",
    },
    {
        title: "Вишневая глазурь",
        size: null,
        sizeType: "",
        uno: null,
        media: 242,
        grande: null,
        description: null,
        unique: true,
        tooltip: "Крепкий кофейный напиток с добавлением топленого молока и Нуги.",
    },
    {
        title: "Баунти Флэт",
        size: null,
        sizeType: "",
        uno: null,
        media: 242,
        grande: null,
        description: null,
        unique: true,
        tooltip: "Крепкий кокосово-шоколадный Флэт. Два эспрессо, молоко, какао, сироп кокос, кокосовая стружка.",
    },
    {
        title: "Пряный капучино",
        size: null,
        sizeType: "",
        uno: null,
        media: 224,
        grande: 238,
        description: null,
        unique: true,
        tooltip:
            "Дерзкий вкус и смелое сочетание ингредиентов! Эспрессо, молоко, сироп фисташка, кардамон, соль, мускатный орех.",
    },
    {
        title: "Ежевика в шоколаде",
        size: null,
        sizeType: "",
        uno: null,
        media: 224,
        grande: 238,
        description: null,
        unique: true,
        tooltip:
            "Отлично подойдет для любителей сладкого. Сочетание нежного кофе с сиропами ежевика и шоколадное печенье.",
    },
    {
        title: "Лавандовый бабл-гам",
        size: null,
        sizeType: "",
        uno: null,
        media: 224,
        grande: 238,
        description: null,
        unique: true,
        tooltip: "Нежнейший латте с сиропом бабл-гам и маршмеллоу.",
    },
    {
        title: "Ванильный крем-латте",
        size: null,
        sizeType: "",
        uno: null,
        media: 224,
        grande: 238,
        description: null,
        unique: true,
        tooltip: "Как латте, только еще нежнее и вкуснее! Эспрессо, молоко, сливки, сироп персик и ваниль.",
    },
];

export const menuListDrinks = [
    {
        title: "Матча Латте",
        size: null,
        sizeType: "",
        uno: null,
        media: 194,
        grande: 215,
        description: null,
        unique: false,
        tooltip: "Освежающий, бодрящий не кофейный напиток, обогащенный большим количеством витаминов и минералов.",
    },
    {
        title: "Чай",
        size: null,
        sizeType: "",
        uno: null,
        media: 134,
        grande: 153,
        description: null,
        unique: false,
        tooltip:
            "Лимон с имбирем, Масала, Улун молочный, Фитнес, Смородиновый, Чабрец с лимоном, Богатырский, Ассам, Земляника со сливками, Ежевика с клюквой, Дянь Хун, Клубника-апельсин",
    },
    {
        title: "Горячий шоколад",
        size: null,
        sizeType: "",
        uno: 188,
        media: 208,
        grande: null,
        description: null,
        unique: false,
        tooltip: "Согревающий шоколадный напиток, который можно кушать ложкой при остывании.",
    },
    {
        title: "Какао",
        size: null,
        sizeType: "",
        uno: 138,
        media: 168,
        grande: 198,
        description: "Детям маршмэллоу бесплатно",
        unique: false,
        tooltip: "Всем знакомый с детства какао - просто и вкусно!",
    },
    {
        title: "Детский латте",
        size: null,
        sizeType: "",
        uno: 104,
        media: 123,
        grande: null,
        description: null,
        unique: false,
        tooltip: "Горячее молоко с любым сиропом на выбор.",
    },
];

export const menuListOtherDrinks = [
    {
        title: "Кофе",
        size: "1",
        sizeType: "kilogram",
        price: 1500,
        tooltip:
            "Кофейные зерна. Бразилия Сантос. Сбалансированный вкус с нотками молочного шоколада, сливочной карамели и ореха.",
    },
    {
        title: "Лимон с имбирем",
        size: "50",
        sizeType: "gram",
        price: 189,
        tooltip: "Китайский зелёный чай, лемонграсс, имбирь, лепестки календулы, ароматизатор пищевой",
    },
    {
        title: "Масала",
        size: "50",
        sizeType: "gram",
        price: 189,
        tooltip: "Индийский чёрный чай, корица, имбирь, бадьян, гвоздика, перец душистый, кардамон ",
    },
    {
        title: "Улун молочный",
        size: "50",
        sizeType: "gram",
        price: 259,
        tooltip: "Китайский зелёный чай, ароматизатор пищевой",
    },
    {
        title: "Фитнес",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip: "Лемонграсс, каркаде, ягоды шиповника и годжи, ароматизатор пищевой",
    },
    {
        title: "Лесной сбор",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip:
            "Сосновая почка, мята, лист берёзы, брусники, толокнянки, ежевики, смородины, ягоды красной рябины, лепестки василька",
    },
    {
        title: "Смородиновый",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip:
            "Индийский чёрный чай, смородиновый лист, мята перечная, ягоды красной смородины и можжевельника, цвет мальвы, лепестки роз, ароматизатор пищевой",
    },
    {
        title: "Чабрец с лимоном",
        size: "50",
        sizeType: "gram",
        price: 189,
        tooltip: "Китайский зелёный чай, цедра апельсина, горный чабрец, лепестки розы, ароматизатор пищевой",
    },
    {
        title: "Богатырский",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip:
            "Индийский чёрный чай, ягоды черноплодной и красной рябины, клюквы, толокнянка, лист ежевика, саган-дайля, цвет мальвы, ароматизатор пищевой",
    },
    {
        title: "Красотка",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip: "Мелисса, яблоко, лист брусники и толокнянки, бадан, анис, ягоды клюквы, лепестки розы, сенна",
    },
    {
        title: "Ассам",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip: "100% индийский черный чай",
    },
    {
        title: "Клубника со сливками",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip: "Индийский чёрный чай, толокнянка, ягоды клубники, ароматизатор пищевой",
    },
    {
        title: "Ежевика с клюквой",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip: "Индийский чёрный чай, гибискус, ягоды ежевики, красной смородины, ароматизатор пищевой",
    },
    {
        title: "Ройбуш ананас/имбирь",
        size: "50",
        sizeType: "gram",
        price: 189,
        tooltip: "Ройбуш, цукат ананаса, имбирь, корица, розовый и душистый перец, гвоздика, ароматизатор пищевой",
    },
    {
        title: "Дянь Хун",
        size: "50",
        sizeType: "gram",
        price: 259,
        tooltip: "Китайский красный чай",
    },
    {
        title: "Цветик-семицветик",
        size: "50",
        sizeType: "gram",
        price: 159,
        tooltip:
            "Цвет ромашки и мальвы, лист толокнянки и мелиссы, лепестки календулы, розы и лилии, ягоды шиповника, боярышника и клубники",
    },
    {
        title: "Клубника-апельсин",
        size: "50",
        sizeType: "gram",
        price: 189,
        tooltip: "Индийский чёрный чай, цедра апельсина, мелисса, ягоды клубники, ароматизатор пищевой",
    },
    {
        title: "Матча",
        size: "50",
        sizeType: "gram",
        price: 189,
        tooltip: null,
    },
    {
        title: "VALDAI негазированная",
        size: "0,5",
        sizeType: "liter",
        price: 72,
        tooltip: "Вода негазированная",
    },
    {
        title: "VALDAI газированная",
        size: "0,5",
        sizeType: "liter",
        price: 72,
        tooltip: "Вода газированная",
    },
    {
        title: "Сиропы",
        size: "250",
        sizeType: "milliliter",
        price: 248,
        tooltip: "Сиропы в ассортименте.",
    },
    {
        title: "Морс",
        size: "0,5",
        sizeType: "liter",
        price: 108,
        tooltip: "Морс облепиховый и вишневый",
    },
    {
        title: "Смузи",
        size: "0,3",
        sizeType: "liter",
        price: 114,
        tooltip: "Смузи банан-манго",
    },
];
